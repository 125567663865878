import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { ethers } from 'ethers';
import { TokenSchema } from 'typings';
import { NFT, ConnectWalletButton, NFTDetails } from 'components';
import { SidebarProps } from './Sidebar.props';
import './style.scss';

export const Sidebar = ({
  tokens,
  isVisible,
  onClose,
  onDetailPress,
  selectedTokenItem,
  connectedAddress,
}: SidebarProps) => {
  const [selectedToken, setSelectedToken] = useState<TokenSchema | undefined>(
    undefined
  );

  useEffect(() => {
    setSelectedToken(selectedTokenItem);
  }, [JSON.stringify(selectedTokenItem)]);

  const renderData = () => {
    if (selectedToken?.docId) {
      return (
        <NFTDetails
          connectedAddress={connectedAddress}
          token={selectedToken}
          onClose={() => setSelectedToken(undefined)}
        />
      );
    }
    return tokens.map(token => (
      <NFT
        token={token}
        onClick={token => {
          setSelectedToken(token);
          onDetailPress(token);
        }}
      />
    ));
  };

  return (
    <div className={`Component_SideBar${!isVisible ? '-closed' : ''}`}>
      <div className='Component_SideBarClose' onClick={onClose}>
        <FaChevronLeft />
      </div>
      <div className='Component_SideBar-list'>{renderData()}</div>
    </div>
  );
};
