import iziToast from 'izitoast';
import { ethers } from 'ethers';
import { MdLocationPin } from 'react-icons/md';
import useStore from 'store/useStore';
import { FaBoxOpen, FaChevronLeft } from 'react-icons/fa';
import { images } from 'assets';
import { abi, address } from 'contract';
import { DetailsProps } from './Details.props';
import './style.scss';

export const NFTDetails = ({
  token,
  onClose,
  connectedAddress,
}: DetailsProps) => {
  const location = useStore(state => state.location);

  const getOnClick = async () => {
    if (connectedAddress) {
      if (parseInt(token.distanceTo) < 5) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(
            address,
            abi,
            provider.getSigner()
          );
          await contract.mint(token.id, 1);
          iziToast.success({
            title: 'Successfully minted token!',
          });
        } catch (e: any) {
          iziToast.error({
            title: 'Error minting token',
            message: 'You might not have enough funds to mint this token.',
          });
        }
      } else {
        iziToast.warning({
          title: 'You are not within 5 miles of proximity.',
        });
      }
    } else {
      iziToast.warning({
        title: 'No Wallet Connected.',
      });
    }
  };

  return (
    <div className='Component_Details'>
      <div className='back-button' onClick={onClose}>
        <FaChevronLeft size='1.2rem' />
      </div>
      <div className='container'>
        <div>
          <img src={token.imageUri} alt={token.title} className='image' />
        </div>
        <div className='title'>
          <div className='title-main'>{token.title}</div>
        </div>
        <div className='title-owner'>
          <div> By: {token.owner}</div>
          <div className='title-location'>
            <MdLocationPin color='red' size='1.2rem' />
            {token.distanceTo || '10'} mi
          </div>
        </div>
        <div className='title'>
          <div className='sub-title'>
            <FaBoxOpen size='1.4rem' /> {token.supplyLeft}
          </div>
          <div className='sub-title'>
            <img src={images.Matic} alt='MATIC' />
            {Math.round(token.price.eth * 10000) / 10000}
          </div>
        </div>
        <div></div>
        <div className='button' onClick={getOnClick}>
          Mint
        </div>
      </div>
    </div>
  );
};
