import React from 'react';
import { TiThMenu } from 'react-icons/ti';
import { SidebarButtonProps } from './SidebarButton.props';
import './style.scss';

export const SidebarButton = ({ onClick }: SidebarButtonProps) => {
  return (
    <div className='Component_BasicButton' onClick={onClick}>
      <TiThMenu size='40' color='white' />
    </div>
  );
};
