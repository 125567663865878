import axios from 'axios';
import qs from 'qs';

const baseURL = 'https://nft-local-api.kingsland.io';

let AxiosModule = axios.create({
  baseURL,
  paramsSerializer: params => qs.stringify(params),
});

AxiosModule.interceptors.request.use(
  async (config: any) => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

AxiosModule.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error.response || error);
  }
);

export { AxiosModule };
