import { IoMdWallet } from 'react-icons/io';
import { images } from 'assets';
import { HeaderProps } from './Header.props';
import './style.scss';

export const Header = ({ walletClick, walletAddress }: HeaderProps) => {
  return (
    <div className='Component_Header'>
      <img src={images.Logo} alt='NFT LOCAL' width={220} height={60} />
      <div className='Component_Header-data'>
        <div className='Component_Header-wallet' onClick={walletClick}>
          <IoMdWallet
            color={walletAddress ? '#B2D2A4' : '#4d566c'}
            size='2.5rem'
          />
        </div>
      </div>
    </div>
  );
};
