import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Dashboard } from 'pages';
import 'App.css';
import 'mapbox-gl/dist/mapbox-gl.css';

export const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/dashboard' element={<Dashboard />} />
    </Routes>
  );
};
