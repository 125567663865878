import React from 'react';
import { MdLocationPin } from 'react-icons/md';
import { FaBoxOpen, FaCoins } from 'react-icons/fa';
import { RiCopperCoinFill } from 'react-icons/ri';
import { images } from 'assets';
import { NFTProps } from './NFT.props';
import './style.scss';

export const NFT = ({ token, onClick }: NFTProps) => {
  return (
    <div className='Component_NFT' onClick={() => onClick(token)}>
      <div className='data-top'>
        <img src={token.imageUri} alt='ING' className='image' />
        <div className='top-text'>
          <div className='text-location'>{token.owner}</div>
          <div className='text-name'>{token.title}</div>
          <div className='text-location'>
            <MdLocationPin color='red' size='1.2rem' />
            {token.distanceTo || 'Distance'} miles
          </div>
        </div>
      </div>
      <div className='data-bottom'>
        <div className='text-supply'>
          <FaBoxOpen size='1.4rem' /> {token.supplyLeft}
        </div>
        <div className='text-supply'>
          <img src={images.Matic} alt='MATIC' />{' '}
          {Math.round(token.price.eth * 10000) / 10000}
        </div>
      </div>
    </div>
  );
};
