import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';
import { TokenSchema, Location } from 'typings';
import { NFT } from 'models';
import { tokenInitialValue } from 'initialValues';

interface FetchAllTokensParams {
  currentLocation: Location;
}

export interface NftSlice {
  tokens: TokenSchema[];
  fetchingTokens: boolean; // can be use to inform user its fetchinga
  ownedNfts: TokenSchema[];
  fetchingOwnedNfts: boolean;
  selectedToken: TokenSchema;
  setTokens: (tokens: TokenSchema[]) => void;
  selectToken: (tokens: TokenSchema) => void;
  fetchAllTokens: (params: FetchAllTokensParams) => void;
  setOwnedNfts: (ownedNfts: TokenSchema[]) => void;
  fetchOwnedNfts: (walletAddress: string) => void;
}

const createNftSlice = (set: SetState<MyState>, _: GetState<MyState>) => ({
  tokens: [],
  fetchingTokens: false,
  ownedNfts: [],
  fetchingOwnedNfts: false,
  selectedToken: tokenInitialValue,
  selectToken: (selectedToken: TokenSchema) => {
    set({
      selectedToken,
    });
  },
  setTokens: (tokens: TokenSchema[]) => {
    set({
      tokens,
    });
  },
  fetchAllTokens: async ({ currentLocation }: FetchAllTokensParams) => {
    try {
      set({
        fetchingTokens: false,
      });
      const tokens = await NFT.fetchAll({ currentLocation });
      set({
        tokens,
      });
    } catch (error) {
    } finally {
      set({
        fetchingTokens: false,
      });
    }
  },
  setOwnedNfts: (ownedNfts: TokenSchema[]) => {
    set({
      ownedNfts,
    });
  },
  fetchOwnedNfts: async (walletAddress: string) => {
    try {
      set({
        fetchingOwnedNfts: true,
      });

      const ownedNfts = await NFT.fetchOwned(walletAddress);

      set({
        ownedNfts: ownedNfts || [],
      });
    } catch (error) {
      set({
        ownedNfts: [],
      });
    } finally {
      set({
        fetchingOwnedNfts: false,
      });
    }
  },
});

export default createNftSlice;
