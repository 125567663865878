import create from 'zustand';

import createNftSlice, { NftSlice } from './createNftSlice';
import createUserSlice, { UserSlice } from './createUserSlice';

export type MyState = NftSlice & UserSlice

const useStore = create<MyState>((set, get) => ({
  ...createNftSlice(set, get),
  ...createUserSlice(set, get),
}));

export default useStore;
