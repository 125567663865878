import Map, { Marker } from 'react-map-gl';
import Image from 'react-bootstrap/Image';
import iziToast from 'izitoast';
import useStore from 'store/useStore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { geolocated, GeolocatedProps } from 'react-geolocated';
import { SidebarButton, Header } from 'components';
import { Sidebar } from 'pages';
import { TokenSchema } from 'typings';
import './style.scss';

const accessToken = process.env.REACT_APP_MAP_ACCESS_TOKEN || '';

const polygonDetails = [
  {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com/'],
    rpcUrls: ['https://polygon-rpc.com/'],
  },
];

const DashboardView = ({
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
}: GeolocatedProps) => {
  const tokens = useStore(state => state.tokens);
  const location = useStore(state => state.location);
  const selectedToken = useStore(state => state.selectedToken);
  const selectToken = useStore(useCallback(state => state.selectToken, []));

  const fetchAllTokens = useStore(
    useCallback(state => state.fetchAllTokens, [])
  );
  const [connectedAddress, setConnectedAddress] = useState<string | undefined>(
    undefined
  );
  const setLocation = useStore(useCallback(state => state.setLocation, []));
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const mapRef = useRef<any>();

  const [viewState, setViewState] = useState({
    longitude: -114.928264,
    latitude: 36.091091,
    zoom: 9,
  });

  useEffect(() => {
    if (isGeolocationEnabled && isGeolocationAvailable && coords) {
      setLocation({
        latitude: coords.latitude,
        longitude: coords.longitude,
      });

      setViewState({
        latitude: coords.latitude,
        longitude: coords.longitude,
        zoom: 13,
      });
    }
  }, [isGeolocationEnabled, isGeolocationAvailable, coords, mapRef]);
  useEffect(() => console.log(process.env), []);

  useEffect(() => {
    if (location && location.latitude && location.longitude) {
      fetchAllTokens({
        currentLocation: location,
      });
    }
  }, [JSON.stringify(location || {})]);

  const onNftClick = useCallback(
    (token: TokenSchema) => {
      setSidebarVisible(true);
      selectToken(token);
      if (
        token.location?.latitude &&
        token.location?.longitude &&
        mapRef.current
      ) {
        mapRef.current.flyTo({
          center: [token.location?.longitude, token.location?.latitude],
          zoom: 14,
        });
      }
    },
    [mapRef.current, selectToken, selectedToken]
  );

  const renderMarkers = useMemo(
    () =>
      tokens.map(nft => {
        return nft.location ? (
          <Marker
            latitude={nft.location?.latitude}
            longitude={nft.location?.longitude}
            key={nft.docId}
            onClick={() => onNftClick(nft)}
          >
            <Image
              src={nft.imageUri}
              roundedCircle
              className={`container-image${
                nft.docId === selectedToken.docId ? '-active' : ''
              }`}
            />
          </Marker>
        ) : null;
      }),
    [tokens, mapRef.current, selectedToken]
  );

  const renderUserLocation = useMemo(
    () =>
      location.longitude ? (
        <Marker latitude={location.latitude} longitude={location.longitude}>
          <div className='container-userlocation' />
        </Marker>
      ) : null,
    [location]
  );

  const connectWallet = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log('MetaMask Here!');

      window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: polygonDetails,
        })
        .then((result: any) => {
          window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((result: any) => {
              iziToast.success({
                title: 'Wallet Connected!',
                message: `${result[0].substring(0, 7)}...`,
              });
              setConnectedAddress(result[0]);
            })
            .catch((error: any) => {
              alert(error.message);
            });
        })
        .catch((error: any) => {
          alert(error.message);
        });
    } else {
      console.log('Need to install MetaMask');
    }
  };

  return (
    <>
      <Header walletClick={connectWallet} walletAddress={connectedAddress} />
      <Map
        ref={mapRef}
        mapStyle='mapbox://styles/mapbox/streets-v9'
        style={{
          height: '100vh',
          width: '100vw',
        }}
        {...viewState}
        scrollZoom
        onMove={evt => setViewState(evt.viewState)}
        mapboxAccessToken={accessToken}
      >
        {renderMarkers}
        {renderUserLocation}
        <Sidebar
          connectedAddress={connectedAddress}
          tokens={tokens}
          selectedTokenItem={selectedToken}
          isVisible={isSidebarVisible}
          onClose={() => setSidebarVisible(false)}
          onDetailPress={currentToken => onNftClick(currentToken)}
        />
        <SidebarButton onClick={() => setSidebarVisible(true)} />
      </Map>
    </>
  );
};

export const Dashboard = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(DashboardView);