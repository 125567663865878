import { TokenSchema, Location } from 'typings';
import { AxiosModule } from 'utils';
import axios from 'axios';

export class NFT implements TokenSchema {
  [key: string]: any;
  id = '0';
  image = '';
  imageUri = '';
  distanceTo = '';
  price = {
    eth: 0,
    wei: 0,
  };
  title = '';
  owner = '';
  description = '';
  category = '';
  location = {
    longitude: 0,
    latitude: 0,
  };
  likes = [];
  supplyLeft = 0;
  createdAt = '';
  updatedAt = '';
  docId = '';
  tokenOwner = '';

  constructor(props: TokenSchema) {
    if (props) {
      Object.assign(this, props);
    }
  }

  static async update(docId: string, payload: Partial<TokenSchema>) {
    const { data } = await AxiosModule.patch(`/nfts/${docId}`, payload);

    return data;
  }

  static async fetchAll({ currentLocation }: { currentLocation: Location }) {
    try {
      const response = await AxiosModule.get('/nfts', {
        params: {
          currLatitude: currentLocation.latitude,
          currLongitude: currentLocation.longitude,
        },
      });

      const parsedData = response.data.map((token: any) => {
        const { name, tokenOwner, attributes, ...props } = token;

        return {
          ...props,
          title: name,
          tokenOwner,
          owner: `${tokenOwner.substring(0, 9)}...`,
          category: attributes[0].value,
        };
      });
      return parsedData;
    } catch (e: any) {
      if (axios.isCancel(e)) {
        throw e;
      }

      throw new Error(e);
    }
  }

  static async fetchOwned(walletAddress: string): Promise<TokenSchema[]> {
    try {
      const { data } = await AxiosModule.get('/nft/owned', {
        params: { walletAddress },
      });

      return data.nfts;
    } catch (e: any) {
      if (axios.isCancel(e)) {
        throw e;
      }

      throw new Error(e);
    }
  }

  static async isNearby({
    currentLocation,
    nftLocation,
    radius,
  }: {
    currentLocation: Location;
    nftLocation: Location;
    radius: number;
  }): Promise<{ isNearby: boolean }> {
    try {
      const { data } = await AxiosModule.get('/nft/is-nearby', {
        params: {
          currentLatitude: currentLocation.latitude,
          currentLongitude: currentLocation.longitude,
          nftLatitude: nftLocation.latitude,
          nftLongitude: nftLocation.longitude,
          radius,
        },
      });

      return data;
    } catch (e: any) {
      if (axios.isCancel(e)) {
        throw e;
      }

      throw new Error(e);
    }
  }

  static async getContractEvents() {
    try {
      const { data } = await AxiosModule.get('/nft/events');

      return data;
    } catch (e: any) {
      if (axios.isCancel(e)) {
        throw e;
      }

      throw new Error(e);
    }
  }
}
