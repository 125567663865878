import { TokenSchema, UserSchema } from "typings";

export const tokenInitialValue: TokenSchema = {
  category: '',
  description: '',
  id: '',
  imageUri: '',
  location: {
    latitude: 0,
    longitude: 0
  },
  owner: '',
  price: {
    eth: 0,
    wei: 0
  },
  title: '',
  createdAt: '',
  distanceTo: '',
  image: '',
  likes: [],
  supplyLeft: 0,
  updatedAt: '',
  ownerDetails: undefined,
  docId: '',
  tokenOwner: '',
  ownerBlocked: false,
}

export const userInitialValue: UserSchema = {
  _id: '',
  email: '',
  username: '',
  description: '',
  discordLink: '',
  profilePictureUrl: '',
  twitterlink: '',
  walletAddresses: [],
  likedNfts: [],
  blockedUsers: [],
  address: '',
}