import {GetState, SetState} from 'zustand';
import { Location } from 'typings';
import {MyState} from './useStore';

export interface UserSlice {
  location: Location;
  setLocation: (loc: Location) => void
}

const createUserSlice = (set: SetState<MyState>, _: GetState<MyState>) => ({
  location: {
    longitude: 0,
    latitude: 0,
  },
  setLocation: (location: Location) => {
    set({
      location
    })
  }
})

export default createUserSlice;
